import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import {
  ActiveProductContextValue,
  useActiveProduct,
} from "@/core/context/ActiveProductContext"
import { AuthUserData } from "@/core/context/AuthUserContext"
import { Location } from "history"
import { useCallback } from "react"
import { generatePath, useHistory } from "react-router-dom"
import ROUTE_NAMES from "../../core/route/util/routeNames"
import {
  isProductDetailRootPath,
  LocationState,
  redirectToDomain,
} from "../../core/route/util/routeUtils"

type RedirectAfterLoginCallback = (
  user: AuthUserData<true>,
  location: Location<LocationState | undefined>
) => void

/** Returns a callback function which redirects a newly logged in user. */
function useRedirectAfterLogin(): RedirectAfterLoginCallback {
  const history = useHistory()

  const activeOrganization = useActiveOrganization()
  const activeProduct = useActiveProduct()

  return useCallback(
    (user, location) => {
      // If the location state provides a redirect, use it.
      if (location.state?.redirectUrl) {
        window.location.href = location.state.redirectUrl
        return undefined
      }
      if (location.state?.from?.pathname) {
        if (activeOrganization) {
          // Hard refresh to the location if we have an active organization
          window.location.href =
            activeOrganization.primaryDomain +
            modifyRedirectPathname(location.state.from.pathname, activeProduct) +
            (location.state.from.search ? location.state.from.search : "")
          return undefined
        }

        return history.replace({
          ...location.state.from,
        })
      }
      // If we are already in a specific organization but don't know a path
      // to go to, we just go to the organization home.
      if (activeOrganization) {
        // Hard refresh to load viewerMembership on active organization and avoid getting
        // stuck on the community landing page
        window.location.href =
          activeOrganization.primaryDomain + ROUTE_NAMES.COMMUNITY.HOME.ROOT
        return
      }

      // We are not within an organization, so take the user to the first
      // organization they belong to.
      const firstOrgMembership = user.lastActiveOrganizationMembership
      if (firstOrgMembership) {
        redirectToDomain(
          firstOrgMembership.organization.primaryDomain,
          ROUTE_NAMES.COMMUNITY.HOME.ROOT
        )
        return undefined
      }

      return history.replace({
        pathname: ROUTE_NAMES.ONBOARDING.V2.GETTING_STARTED,
      })
    },
    [activeOrganization, activeProduct, history]
  )
}

/**
 * Modify the pathname we will redirect to if a logged in user
 * should be taken to a different page than they came from.
 */
function modifyRedirectPathname(
  pathname: string,
  product: Pick<ActiveProductContextValue, "slug"> | null
): string {
  // Coming from the community landing page, redirect to /home page.
  if (pathname === ROUTE_NAMES.ROOT) {
    return ROUTE_NAMES.COMMUNITY.HOME.ROOT
  }
  // Coming from the product landing page, redirect to overview page.
  if (isProductDetailRootPath(pathname) && product) {
    return generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
      productSlug: product.slug,
    })
  }
  return pathname
}

export { useRedirectAfterLogin }
